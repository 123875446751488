/* clinics */
export  { Clinic } from './donations/clinics';

/* user */
export { UserInfo , UserRes } from './donations/user';

export { ProfessionalFromJson , ProfessionalsJSONRes } from 'src/app/models/donations/professionalsJsonClass.models';

export { AppState } from 'src/app/models/appState.models';

export { DonationsFilters } from 'src/app/models/donations/donationsFilters.models';

export { DonationsRes , Donation , File } from 'src/app/models/donations/donation.models';

export { PaginationParams } from 'src/app/models/donations/paginationParams.models';

export { DonationStatus } from 'src/app/models/donations/enums/donation.status.enum';

export { DonationStartData } from 'src/app/models/donations/donationStartData';

export { DonationProjects } from 'src/app/models/donations/donation-projects.models';

export { DonationToUpdate } from 'src/app/models/donations/donation.models';

export { CertificateResponse } from './donations/CertificateResponse.models';

export { DonationAuditLogResponse , AuditLog , DonationAuditDiff } from './donations/donationAuditLogs.models';

export { DonationAuditLogsfilters } from './donations/donationAuditLogsFilters.models';

export { DonationsForSepaFilters } from './donations/donationsForSepafilters.models';

export { SepaRes , Sepa } from './donations/sepa.models';

export { SepasFilters } from './donations/sepasFilters.models';

export { DonationsInsepaFilters } from 'src/app/models/donations/donationsFilters.models';

export { BlobReference } from './donations/blobReference.models';

export { ApiResponse } from './donations/setCertificateAsSentReponse.models';

export { AlertContent } from './shared/alert.models';

export { DeleteAlertContent } from 'src/app/models/donations/deleteAlertContent.models';

export { DonationStatusTypes } from './donations/donationsStatusRes.models';



