import { createReducer, on } from "@ngrx/store";
import { AuditLog, DonationAuditLogResponse } from "src/app/models";
import * as actions from 'src/app/store/actions';

export interface donationAuditLogsState {
    DonationProjects : DonationAuditLogResponse;
}

export const initialUserEntries : DonationAuditLogResponse = new DonationAuditLogResponse() ;

export const DonationAuditLogsReducer = createReducer(
    initialUserEntries,

    on( actions.AddSingleDonationAuditLogsSuccess , (state , { donationAuditLogResponse }) => {

        state = { ...donationAuditLogResponse , items : [ ...donationAuditLogResponse.items ].map(( al : AuditLog ) => { return { ...al , friendlyId : al.donationId.split('-')[0] } }) };

        return state;
    }),

    on( actions.AddAllDonationAuditLogsSuccess , (state , { donationAuditLogResponse }) => {

        state = { ...donationAuditLogResponse , items : [ ...donationAuditLogResponse.items ].map(( al : AuditLog ) => { return { ...al , friendlyId : al.donationId.split('-')[0] } }) };
        
        return state;
    })
) 