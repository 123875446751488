import { createReducer, on } from "@ngrx/store";
import { DonationProjects } from "src/app/models";
import * as actions from "src/app/store/actions";

export interface donationProjectsState {
    DonationProjects : DonationProjects[];
}

export const initialUserEntries : DonationProjects[] = [ new DonationProjects() ] ;

export const DonationProjectsReducer = createReducer(
    initialUserEntries,

    on( actions.storeDonationProjectSuccess , (state , { donationProjects }) => {

        state = donationProjects.map(( dp : DonationProjects ) => { 

            return { 
                ...dp , 
                status : 'projects_assignement' , 
                friendlyId : dp.id.split('-')[0] ,
                createdDateTime : new Date(dp.createdOn).toLocaleDateString()
            }});

        return state;
    }),

    on( actions.storeDonationProjectFailure , (state , { error }) => {

        state[0] = { ...state[0] , error : error };
        
        return state;
    }),

    on( actions.deleteDonationProjectSuccess , ( state , { deleteProjectId } ) => {

        if( deleteProjectId ){

            return state.filter(( dp : DonationProjects ) => dp.id !== deleteProjectId );
        
        } else {

            return state;
        }

    }),

    on( actions.updateDonationProjectSuccess , (state , { updateDonationProject } ) => {

        const { id } = updateDonationProject || {};

        if( id ){

            return [...state].map(( dp : DonationProjects ) => {

                return id === dp.id ? { ...updateDonationProject } : { ...dp };
            })
        
        } else {

            return state;
        }
    }),

    on( actions.addDonationProjectSuccess , ( state , { donationProject } ) => {

        return [ { 

            ...donationProject , 
            status : "projects_assignement" , 
            friendlyId : donationProject.id.split('-')[0] ,
            createdDateTime : new Date(donationProject.createdOn).toLocaleDateString()
        
        } , ...state ];
    })
) 