<div class="side_menu-main-container" >

    <div class="side_menu-first_menu" >

        <div class="side_menu-items_container c-menu__link"
            [ngClass]="{ 'side_menu-active_path' : activedPage === 'donations' }" 
            (click)="GoToPage('donations')"
        >
            <fa-icon [icon]="faHandHoldingDollar"  ></fa-icon>
        </div>

        <div *ngIf="isAuditor" class="side_menu-items_container c-menu__link"
            [ngClass]="{ 'side_menu-active_path' : activedPage === 'auditLogs' }" 
            (click)="GoToPage('logs')"
        >
            <fa-icon [icon]="faClipboard"  ></fa-icon>
        </div>

        <div *ngIf="isAdmin" class="side_menu-items_container c-menu__link"
            [ngClass]="{ 'side_menu-active_path' : activedPage === 'sepas' }" 
            (click)="GoToPage('sepas')"
        >
            <fa-icon [icon]="faFile"  ></fa-icon>
        </div>

        <div *ngIf="isAdmin" class="side_menu-items_container c-menu__link"
            [ngClass]="{ 'side_menu-active_path' : activedPage === 'projects' }" 
            (click)="GoToPage('projects')"
        >
            <fa-icon [icon]="faPersonDigging"  ></fa-icon>
        </div>

    </div>

</div>
