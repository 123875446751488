import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError,  map, switchMap } from 'rxjs/operators';
import { DonationProjectsService } from 'src/app/donations/services';
import { DonationProjects } from 'src/app/models';
import { ComponentToSpinnerService } from 'src/app/shared/services';
import * as actions from 'src/app/store/actions';

@Injectable()

export class DonationProjectEffects {

    constructor( 
        private action$ :                   Actions,
        private donationProjectService:     DonationProjectsService,
        private componentToSpinnerService:  ComponentToSpinnerService
    ){}

    storeDonationProjects$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.storeDonationProject),
            switchMap( () =>
                {
                    return this.donationProjectService.LoadDonationProjects()
                    .pipe(
                        map( ( donationProjects : DonationProjects[] ) => {

                            this.componentToSpinnerService.sendMessage('hide');

                            return actions.storeDonationProjectSuccess ({ donationProjects });
                        }),
                        catchError(error => {

                            this.componentToSpinnerService.sendMessage('hide');

                            return of( actions.storeDonationProjectFailure({ error : error }) )
                        })
                    )
                }
            )
        )
    })

    addDonationProject$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.addDonationProject),
            switchMap(action =>
                {
                    const { donationProject }  = action || {};
                    
                    return this.donationProjectService.CreateDonationProjects( donationProject )
                    .pipe(
                        map((donationProject : DonationProjects) => {

                            this.componentToSpinnerService.sendMessage('hide');

                            return actions.addDonationProjectSuccess ({ donationProject : donationProject });
                        }),
                        catchError(error => {

                            this.componentToSpinnerService.sendMessage('hide');

                            return of( actions.addDonationProjectFailure({ error : error }) )
                        })
                    )

                }
            )
        )
    })

    deleteDonationProject$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.deleteDonationProject),
            switchMap(action =>
                {
                    const { deleteProjectId }  = action || {};
                    
                    return this.donationProjectService.DeleteDonationProjects( deleteProjectId )
                    .pipe(
                        map((donationRes : boolean) => {

                            console.log(donationRes);
                            
                            this.componentToSpinnerService.sendMessage('hide');

                            if( donationRes ){

                                return actions.deleteDonationProjectSuccess ({ deleteProjectId : deleteProjectId });
                            
                            } else {

                                return actions.deleteDonationProjectSuccess ({ deleteProjectId : "" });
                            }
                        }),
                        catchError(error => {

                            this.componentToSpinnerService.sendMessage('hide');

                            return of( actions.deleteDonationProjectFailure({ error : error }) )
                        })
                    )

                }
            )
        )
    })

    updateDonationProject$ = createEffect(() => {
        return this.action$
        .pipe(
            ofType(actions.updateDonationProject),
            switchMap(action =>
                {
                    const { updateDonationProject }  = action || {};
                    
                    return this.donationProjectService.UpdateDonationProjects( updateDonationProject )
                    .pipe(
                        map((donationRes : boolean) => {

                            this.componentToSpinnerService.sendMessage('hide');

                            if( donationRes ){

                                return actions.updateDonationProjectSuccess ({ updateDonationProject : updateDonationProject });
                            
                            } else {

                                return actions.updateDonationProjectSuccess ({ updateDonationProject : { id: "" , name : "" , description : "" , deleted : false , friendlyId : '' , createdOn : new Date() } });
                            }
                        }),
                        catchError(error => {

                            this.componentToSpinnerService.sendMessage('hide');

                            return of( actions.updateDonationProjectFailure({ error : error }) )
                        })
                    )

                }
            )
        )
    })

}