
export class DonationAuditLogResponse {
    first=                             0;
    size=                              0;
    totalPages=                        0;
    totalCount=                        0;
    items:                             AuditLog[] = [];
}

export class AuditLog {
    createdOn:                         Date = new Date();
    donationId=                        "";
    friendlyId=                        "";
    userId=                            "";
    entity=                            "";
    description=                       "";
    oldValue=                          "";
    newValue=                          "";
    action=                            0;
    propertyName=                      "";
    updateDate=                        "";
    updateTime=                        "";
    actionStr=                         "";
}

/* export class DonationAuditLogResponse {
    first=                             0;
    size=                              0;
    totalPages=                        0;
    totalCount=                        0;
    items:                             AuditLog[] = [];
}

export class AuditLog {
    createdOn:                         Date = new Date();
    donationId=                        "";
    userId=                            "";
    entity=                            "";
    description=                       "";
    oldValueJson:                      NewValueDonation = new NewValueDonation();
    newValueJson:                      NewValueDonation = new NewValueDonation();
    oldValue:                          "";
    newValue:                          "";
}

export class NewValueDonation {
    $id=                               "";
    DateTime:                          Date = new Date();
    DonationStatus?=                    0;
    ProjectId=                         "";
    Project=                           null;
    Files=                             Files;
    Payments=                          Files;
    FirstName=                         "";
    LastName=                          "";
    Amount=                            0;
    AccountNumber=                     "";
    DocumentNumber=                    "";
    Email=                             "";
    Prefix=                            "";
    Phone=                             "";
    Address=                           "";
    City=                              "";
    Province=                          "";
    PostalCode=                        "";
    Country=                           "";
    Type?=                             0;
    Starred=                           false;
    Id=                                "";
    CreatedOn:                         Date = new Date();
    ModifiedOn?=                       null;
    DeletedOn=                         null;
    CreatedByUser=                     "";
    ModifiedByUser=                    null;
    DeletedByUser=                     null;
    Deleted=                           false;
    Status =                           "";
    Kind =                             ""
}

export class Files {
    $id=                               "";
    $values:                           ValueElement[] = [ new ValueElement() ];
}

export class ValueElement {
    $ref?=                             "";
    $id?=                              "";
    DonationId?=                       "";
    Donation?:                         ValueDonation = new ValueDonation();
    Uri?=                              "";
    Description?=                      null;
    SentByEmail?=                      false;
    Type?=                             0;
    Id?=                               "";
    CreatedOn?:                        Date = new Date();
    ModifiedOn?=                       null;
    DeletedOn?=                        null;
    CreatedByUser?=                    "";
    ModifiedByUser?=                   null;
    DeletedByUser?=                    null;
    Deleted?=                          false;
}

export class ValueDonation {
    $ref=                              "";
}

export class DonationAuditDiff {
    createdDate:                       string = new Date().toString();
    createdTime:                       string = new Date().toString();
    description =                      "";
    entity =                           "";
    user =                             "";
    old =                              "";
    new =                              "";
    field =                            ""
} */

export class DonationAuditDiff {
    createdDate:                       string = new Date().toString();
    createdTime:                       string = new Date().toString();
    description =                      "";
    entity =                           "";
    user =                             "";
    old =                              "";
    new =                              "";
    field =                            ""
}


