import { createReducer, on } from "@ngrx/store";
import { DonationsRes } from "src/app/models";
import * as actions from 'src/app/store/actions';

export interface donationInSepaResState {
    DonationInSepaRes : DonationsRes
}

export const initialUserEntries : DonationsRes = new DonationsRes() ;

export const DonationsInSepaReducer = createReducer(
    initialUserEntries,

    on(actions.storeDonationsInAsepaSuccess , ( state , { donationsRes , sepaId } ) => {

        console.log(sepaId);

        state = donationsRes;

        return state;
    }) 
) 