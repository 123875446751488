import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DonationProjects } from 'src/app/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class DonationProjectsService {

  constructor(
    private http :                                                                  HttpClient
  ) { }

  LoadDonationProjects() :Observable<DonationProjects[]> {
    
    return this.http.get<DonationProjects[]>(`${environment.donationsService.url}Projects`);
  }

  CreateDonationProjects( donationProject : DonationProjects ) :Observable<DonationProjects> {
    
    return this.http.post<DonationProjects>(`${environment.donationsService.url}Projects` , donationProject);
  }

  DeleteDonationProjects( donationProjectId : string ) :Observable<boolean> {
    
    return this.http.delete<boolean>(`${environment.donationsService.url}Projects/${donationProjectId}`);
  }

  UpdateDonationProjects( donation : DonationProjects) :Observable<boolean> {
    
    return this.http.put<boolean>(`${environment.donationsService.url}Projects/${donation.id}` , donation);
  }
}
