import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { DonationProjects } from "src/app/models";

export const storeDonationProject = createAction(
    '[Donation Project] store donation project',
    props<{ order : string }>()
)

export const storeDonationProjectSuccess = createAction(
    '[Donation Project] store donation project success',
    props<{ donationProjects : DonationProjects[] }>()
)

export const storeDonationProjectFailure = createAction(
    '[Donation Project] store donation project failure',
    props<{ error : HttpErrorResponse }>()
)

export const addDonationProject = createAction(
    '[Donation Project] add donation project',
    props<{ donationProject : DonationProjects }>()
)

export const addDonationProjectSuccess = createAction(
    '[Donation Project] add donation project success',
    props<{ donationProject : DonationProjects }>()
)

export const addDonationProjectFailure = createAction(
    '[Donation Project] add donation project failure',
    props<{ error : HttpErrorResponse }>()
)

export const deleteDonationProject = createAction(
    '[Donation Project] delete donation project',
    props<{ deleteProjectId : string }>()
)

export const deleteDonationProjectSuccess = createAction(
    '[Donation Project] delete donation project success',
    props<{ deleteProjectId : string }>()
)

export const deleteDonationProjectFailure = createAction(
    '[Donation Project] delete store donation project failure',
    props<{ error : HttpErrorResponse }>()
)

export const updateDonationProject = createAction(
    '[Donation Project] update donation project',
    props<{ updateDonationProject : DonationProjects }>()
)

export const updateDonationProjectSuccess = createAction(
    '[Donation Project] update donation project success',
    props<{ updateDonationProject : DonationProjects }>()
)

export const updateDonationProjectFailure = createAction(
    '[Donation Project] update store donation project failure',
    props<{ error : HttpErrorResponse }>()
)