import { HttpErrorResponse } from "@angular/common/http";

export class DonationProjects {
    id =                                                   "";
    friendlyId =                                           "";
    name=                                                  "";
    description=                                           "";
    error? :                                               HttpErrorResponse;
    status?=                                               "first_assignement";
    createdOn:                                             Date = new Date();
    createdDateTime?=                                      "";
    deleted=                                               false;
}