import { createReducer, on } from "@ngrx/store";
import { Donation, DonationsRes, File } from "src/app/models";
import { CertificateResponse } from "src/app/models/donations/CertificateResponse.models";
import { Payment } from "src/app/models/donations/donation.models";
import * as actions from 'src/app/store/actions';

export interface donationResState {
    DonationRes : DonationsRes
}

export const initialUserEntries : DonationsRes = new DonationsRes() ;

export const DonationsFilteredReducer = createReducer(
    initialUserEntries,

    on( actions.getDonationsFilteredSuccess , (state , { donationsRes }) => {

        state = { 
            ...donationsRes , 
            status:'donations_assignements' , 
            items : [...donationsRes.items].map((d : Donation) => { return { ...d , friendlyId : d.id.split('-')[0] } })
        };

        return state;
    }),

    on( actions.getDonationsFilteredFailure , (state , { error }) => {
        return { ...state , error : error }
    }) , 

    on( actions.updateDonationsSuccess , (state , { donationToUpdate }) => {

        return { 
            ...state , 
            items : [ ...state.items ].map(( d : Donation ) => {

                if( d.id === donationToUpdate?.id ){

                    return { ...d , ...donationToUpdate }
                
                } else {

                    return { ...d };
                }
            })
        };
    }), 


    on( actions.getDonationSingleDonationSuccess , (state , { donation }) => {

        return { ...state, items: [ donation ] };
    }),

    on(actions.AddCertificateToDonationSuccess, (state, { certificates }) => {

        const myDonations: Donation[] = state.items.map((d: Donation) => {

            const { id } = d || {};

            const myCertificate = certificates?.filter(( c: CertificateResponse ) => id === c.donationId);
        
            if (myCertificate && myCertificate.length > 0) {

                const updatedDonation: Donation = {
                    ...d,
                    files: [...myCertificate, ...d.files],
                    hasAuditLogs : true
                };

                return updatedDonation;

            } else {

                return d;
            }
        });
    
        return { ...state, items: myDonations };
    }),

    on(actions.SetSendByEmailPropertyToDonationFileSuccess , (state , { dataForReducer }) => {
   
        const { donationsIds } = dataForReducer || {};
       
        const myDonations : Donation[] = [...state.items].map(( d : Donation ) => {

            const { id } = d || {};

            if( donationsIds.find(( donationId : string ) => donationId === id) ){

                const myFiles = [ ...d.files ].map(( f : File ) => {
                    
                    return f.type === 1 ? { ...f , sentByEmail : true } : f;
                })

                return { ...d , sentByEmail : true , hasAuditLogs : true , files : myFiles };    
            }

            return { ...d };
        })


        return { ...state, items: myDonations };
    }),

    on(actions.storeDonationsInAsepaSuccess , ( state , { donationsRes , sepaId } ) => {

        const myItems = [ ...donationsRes.items ].map(( d : Donation ) => {

            return { ...d , sepaId : sepaId , stateStatus : 'real_assignement' };
        }) 

        return { ...state , items : myItems };
    }),

    on( actions.SetCertificateAsSentInAssociatedDonationSuccess , ( state , { setCertificateAsSentResponse , donationsIds } ) => {

        const { isSuccess } = setCertificateAsSentResponse || {};

        if( isSuccess ){

            return { ...state , items : [ ...state.items ].map(( d : Donation ) => {

                if( d.associated && donationsIds.find(( id : string ) => id == d.id) ){
    
                    return { ...d , associatedCertificateSent : true , hasAuditLogs : true };
                
                } else {

                    return {...d};
                }
            })}

        } else {

            return state;
        }
    }),

    on( actions.SetAllCertificateAsSentInAssociatedDonationSuccess , ( state , { setCertificateAsSentResponse } ) => {

        const { isSuccess } = setCertificateAsSentResponse || {};

        if( isSuccess ){

           return { ...state , items : [ ...state.items ].map(( d : Donation ) => { return { ...d , associatedCertificateSent : true , hasAuditLogs : true } }) }

        } else {

            return state;
        }
    }),

    on( actions.deleteDonationSuccess , ( state , { donationRes , donationId } ) => {

        if( donationRes ){

            return { ...state , items : [ ...state.items ].filter(( d : Donation ) => d.id !== donationId ) };
        
        } else {

            return state;
        }

        
    }),

    on( actions.modifyPaymentSuccess , ( state , { payment }) =>{

        if( payment ){

            return { ...state , items : [ ...state.items ].map(( d : Donation ) => {

                if( d.id === payment.donationId ){

                    return { ...d , payments : [ ...d.payments ].map(( p : Payment ) => {
                        
                        return p.id === payment.id ? { ...payment } : { ...p };
                        
                    })}
                } else {

                    return { ...d };
                }

            }) }

        } else {

            return state;
        }
    }),

    on( actions.deletePaymentSuccess , ( state , { paymentRes , payment } ) => {

        const { donationId , id } = payment || {};

        const { isSuccess } = paymentRes || {};

        if ( isSuccess ){

            return { ...state , items : [ ...state.items ].map(( d : Donation ) => {

                if( d.id === donationId ){
    
                   return { ...d , payments : d.payments.filter(( p : Payment ) => p.id !== id ) };
                
                } else {

                    return d;
                }
            })}
        
        } else {

          return state;            
        }
    }),

    on( actions.createPaymentSuccess , ( state , { paymentRes , payment } ) => {

        const { isSuccess , messages } = paymentRes || {};

        const { donationId , amount } = payment || {};

        if( isSuccess ){

            const newPayment : Payment = {

                id : messages[0].body,
                donationId : donationId,
                amount : amount,
                date: messages[1].body.split(' ')[0],
                time: messages[1].body.split(' ')[1],
                createdByUser : 'System',
                deleted : false
            }

            return { ...state , items : [ ...state.items ].map(( d : Donation ) => {

                if( d.id === donationId ){

                    return { ...d , payments : [ newPayment , ...d.payments ] , hasAuditLogs : true };
                
                } else {

                    return { ...d} ;
                }

            })};
        
        } else {

            return state;
        }
    }),

    on(actions.storeXlsDonationLogSuccess, (state, { apiResponse }) => {

        const { isSuccess } = apiResponse || {};
    
        if (isSuccess) {

           
            return { ...state, items: [ ...state.items ].map((d: Donation) => { return { ...d , hasAuditLogs: true } }) };
        } else {

            return state;
        }
    })
) 