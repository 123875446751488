import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { UserInfo } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class CheckUserRoleService {

  constructor(
    private authService:                                     MsalService
  ) { }

  CheckUserRole():{isAdmin:boolean,isReader:boolean,isAuditor:boolean} | undefined{
    
    const userInfo: UserInfo | AccountInfo = this.authService.instance.getAllAccounts()[0];
    
    let userRoles : {isAdmin:boolean,isReader:boolean,isAuditor:boolean} = {
      isAdmin : false,
      isReader : false,
      isAuditor : false,
    }

    if(userInfo){

      const roles = userInfo.idTokenClaims?.roles;

      if( roles ){

        //test solo admin , del array role reader
        /* let testGroups = roles.filter((e)=>e!=='isReader');
        if( !testGroups.find((e) => e === 'admin') )testGroups.push('admin'); */

        //test solo reader
        /* let testGroups = roles.filter((e)=>e!=='reader');
        if( !testGroups.find((e) => e === 'admin') )testGroups.push('admin'); */
    
        userRoles.isAdmin = !!(roles as string[])/* testGroups */.find((e : string) => e === 'admin');
        userRoles.isReader = !!(roles as string[])/* testGroups */.find((e : string) => e === 'reader');
        userRoles.isAuditor = !!(roles as string[])/* testGroups */.find((e : string) => e === 'auditor');

        return userRoles;
      }else {

        userRoles = { ...userRoles , isAdmin : false , isReader : false , isAuditor : false };
        return userRoles
      }

    } else {
      return undefined;
    }
  }
}
