import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AppState, ProfessionalsJSONRes, UserInfo, UserRes } from './models';

import { 
 faUser , IconDefinition , faHandHoldingDollar
} from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { addUser, getProfessionalsJsonAction } from './store/actions/users/user.actions';
import { ProfessionalService } from './shared/services/httpResquests/professional.service';
import { ProfessionalHelperService } from './shared/services/helpers/professional-helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit , OnDestroy {
  title = 'donations';

  faUser:                                       IconDefinition = faUser;
  isIframe                                      = false;
  logged                                        = false;
  userActivity:                                 ReturnType<typeof setTimeout>;
  userDestroyed$:                               Subject<void> = new Subject<void>();
  faHandHoldingDollar:                          IconDefinition = faHandHoldingDollar;
  professionalsJsonDestroyed$:                  Subject<void> = new Subject<void>();
  
  userLogged:                                   UserInfo = {};
  professionalsJSONRes:                         ProfessionalsJSONRes ;

  constructor(

    private store:                              Store<AppState>, 
    private professionalService:                ProfessionalService,
    private professionalHelperService:          ProfessionalHelperService
  ){}

  ngOnInit(): void {

    this.FillProfessionalInfo();
    this.StoreDoctorsJson();
  }

  FillProfessionalInfo():void{
    
    this.store.select(appState => appState.User).pipe(takeUntil( this.userDestroyed$ )).subscribe((user) => {
      
      this.userLogged = user;

      const {username , code , professionalLoggedId} = user || {};

      if(username){
        
        this.logged = true;

        if(!(user as UserInfo).userRole && !professionalLoggedId){

          this.professionalService.LoadUserByEmail(username).subscribe((res : UserRes)=>{

            if( res ){

              const { id } = res || {};

              this.store.select( AppState => AppState.professionalsJSON ).pipe(takeUntil( this.professionalsJsonDestroyed$ ))
              .subscribe(( professionalsJson : ProfessionalsJSONRes ) => {
                
                const { payload , success } = professionalsJson || {};

                if( success ){

                  const { avatar , username } = this.userLogged || {};

                  if(!avatar && username && !code ){
          
                    const userAvatar = this.professionalHelperService.AddAvatarByEmail( username , payload );
                    

                    const info : UserInfo = {
                      ...user  , avatar : userAvatar , code : 'professional_without_user_code' ,
                      professionalLoggedId : id
                    };
                    this.store.dispatch(addUser(info));
          
                  }
                }
              })
            }
          })
        }
      } else {
        this.logged = false;
      }
    })
  }

  StoreDoctorsJson():void{

    this.store.select( AppState => AppState.professionalsJSON ).pipe(takeUntil( this.professionalsJsonDestroyed$ ))
    .subscribe(( professionalsJson : ProfessionalsJSONRes ) => {

      this.professionalsJSONRes = professionalsJson;

      const { payload } = professionalsJson || {};

      if(payload.length < 1){
        
        this.store.dispatch( getProfessionalsJsonAction({ professionalsFilters : {} }) );
      } 
    })
  }
  
  RecibeFrame(iframe:boolean):void{
    this.isIframe = iframe;
  }

  setTimeout() {
    //this.userActivity = setTimeout(() => this.authComponent.Logout(),900000);
  }

  isLogged(e:boolean):void{
    this.logged = e;
  }

  @HostListener('window:mousemove') refreshUserState() {
    //clearTimeout(this.userActivity);
    //this.setTimeout();
  }

  ngOnDestroy(): void {
    this.userDestroyed$.next();
    this.userDestroyed$.complete();
    this.professionalsJsonDestroyed$.next();
    this.professionalsJsonDestroyed$.complete();
  }
}
