import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AuditLogGuard } from './guards/audit-log.guard';
import { SepasGuard } from './guards/sepas.guard';

export const routes: Routes = [
  
  {
    path: '', redirectTo:'donations' , pathMatch:'full'
  },
  {
    path:'donations',
    loadChildren:()=>import('./donations/donations.module').then(m=>m.DonationsModule),
    canActivate:[MsalGuard]
  },
  {
    path:'auditLogs',
    loadChildren:()=>import('./audit-logs/audit-logs.module').then(m=>m.AuditLogsModule),
    canActivate:[MsalGuard , AuditLogGuard]
  },
  {
    path:'sepas',
    loadChildren:()=>import('./sepas/sepas.module').then(m=>m.SepasModule),
    canActivate:[MsalGuard , SepasGuard]
  },
  {
    path:'projects',
    loadChildren:()=>import('./projects/projects.module').then(m=>m.ProjectsModule),
    canActivate:[MsalGuard , SepasGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
