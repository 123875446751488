import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DeleteAlertContent } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class ComponentToDeletePopUpService {

  private subject = new Subject<DeleteAlertContent>();

  sendMessage( message : DeleteAlertContent ) {

    this.subject.next(message);
  }

  clearMessages() {
    this.subject.next({name:'' , id:'' , action:''});
  }

  onMessage(): Observable<DeleteAlertContent> {
    return this.subject.asObservable();
  }
}
